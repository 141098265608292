import productImages from "../assets/data/productImages";

export const ProductPreviewCardSmall = ({ product, onAddProduct }) => {
  const addProduct = () => {
    const freeProduct = {
      ...product,
      _id: "65645369a21d7b02ad1b12ae",
      isFree: true,
      price: 0,
    };
    onAddProduct(freeProduct);
  };

  return (
    <button
      className="bg-beige-light container flex flex-col items-center border border-brown rounded-lg shadow-western w-32 transform transition-transform duration-50 ease-in-out hover:scale-105 hover:bg-beige active:scale-90"
      onClick={addProduct}>
      <div className="flex flex-col items-center justify-between">
        <h3 className="text-xs italic tracking-tight text-brown">
          Choose your FREE box
        </h3>

        <h1 className="text-sm px-2 pt-2 font-western tracking-tight text-brown">
          {product.name}
        </h1>
      </div>

      <div className="px-2 pb-1 h-16">
        <div className="flex items-center justify-between">
          <div className="img-container w-16">
            <img
              className="p-1 w-full h-full"
              src={productImages[product.name]}
              alt={product.name}
            />
          </div>
        </div>
      </div>
    </button>
  );
};
