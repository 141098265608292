import React, { useEffect } from "react";
import { StockCard } from "./StockCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../stores/product/productSlice";

export function StockPreview() {
  const dispatch = useDispatch();
  const { products, status } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto pb-10 px-4 sm:px-6 lg:px-10 grid grid-cols-2 lg:grid-cols-3 gap-6">
      {status === "fulfilled" &&
        products.map((product, index) => {
          return <StockCard key={index} product={product} />;
        })}
    </div>
  );
}
