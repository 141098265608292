import React, { useEffect } from "react";
import { ProductPreviewCard } from "./ProductPreviewCard";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../stores/cart/cartSlice";
import { fetchProducts } from "../stores/product/productSlice";

export function ProductsPreview() {
  const dispatch = useDispatch();
  const { products, status } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onAddProduct = (product) => {
    dispatch(addToCart(product));
  };

  return (
    <div className="container min-h-[400px] mx-auto pb-10 px-4 sm:px-6 lg:px-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {status === "fulfilled" &&
        products.map((product, index) => {
          return (
            <ProductPreviewCard
              key={index}
              product={product}
              onAddProduct={onAddProduct}
            />
          );
        })}
    </div>
  );
}
