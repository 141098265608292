import {
  createSelector,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import apiURL from "../../config";

const initialState = {
  orders: [],
  error: null,
  status: "idle",
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.orders = action.payload.data;
      })
      .addCase(fetchOrders.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchDeliveredOrders.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.orders = action.payload.data;
      })
      .addCase(fetchDeliveredOrders.pending, (state) => {
        state.status = "pending";
      });
  },
});

export const { getOrders } = ordersSlice.actions;

export default ordersSlice.reducer;

export const fetchOrders = createAsyncThunk("orders/fetchOrders", async () => {
  const response = await fetch(`${apiURL}/api/orders`);
  const data = await response.json();
  return data;
});

export const fetchDeliveredOrders = createAsyncThunk(
  "orders/fetchDeliveredOrders",
  async () => {
    const response = await fetch(`${apiURL}/api/delivered-orders`);
    const data = await response.json();
    return data;
  }
);

export const selectAllOrders = createSelector(
  (state) => state.order.orders,
  (state) => state.order.status,
  (orders, status) => ({ orders, status })
);
