import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCbEBAWDI6On80CY4UXWy74VLQGagTx-A8",
  authDomain: "atelier-sushi.firebaseapp.com",
  databaseURL:
    "https://atelier-sushi-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "atelier-sushi",
  storageBucket: "atelier-sushi.appspot.com",
  messagingSenderId: "154839994985",
  appId: "1:154839994985:web:a9d4d6d777a3c0229208f8",
  measurementId: "G-F8GYZFY9FE",
};

export const firebase = initializeApp(firebaseConfig);
