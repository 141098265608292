import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const existingProductIndex = state.products.findIndex(
        (product) => product._id === action.payload._id
      );

      if (existingProductIndex !== -1) {
        state.products[existingProductIndex].amount += 1;
      } else {
        state.products.push({ ...action.payload, amount: 1 });
      }
    },
    clearCart: (state) => {
      state.products = [];
    },
    incrementProductAmount: (state, action) => {
      return {
        products: state.products.map((product) =>
          product._id === action.payload
            ? { ...product, amount: product.amount + 1 }
            : product
        ),
      };
    },
    decrementProductAmount: (state, action) => {
      const updatedProducts = state.products.map((product) =>
        product._id === action.payload
          ? { ...product, amount: product.amount - 1 }
          : product
      );
      // Filtrar los productos con cantidad mayor a 0
      const filteredProducts = updatedProducts.filter(
        (product) => product.amount > 0
      );

      return {
        products: filteredProducts,
      };
    },
    removeFreeProduct: (state) => {
      const updatedProducts = state.products.filter(
        (product) => !(product.isFree || product.category !== "Sushi")
      );

      return {
        ...state,
        products: updatedProducts,
      };
    },
    updateCart: (state, action) => {
      state.products = action.payload;
    },
  },
});

export const cartProducts = (state) => state.cart.products;

export const calculateTotal = (state) => {
  let total = 0;
  state.cart.products.forEach((product) => {
    total += product.price * product.amount;
  });
  return total;
};

export const {
  addToCart,
  clearCart,
  incrementProductAmount,
  decrementProductAmount,
  removeFreeProduct,
  updateCart,
} = cartSlice.actions;

export default cartSlice.reducer;
