import { useSelector, useDispatch } from "react-redux";
import { cartProducts, addToCart } from "../stores/cart/cartSlice";
import { selectAllProducts } from "../stores/product/productSlice";
import { ProductsSummaryCard } from "./ProductSummaryCard";
import Carousel from "./Carousel";
import cheeseSauce from "../assets/images/cheese-sauce.png";

export const ProductsSummary = () => {
  const dispatch = useDispatch();
  const cart = useSelector(cartProducts);
  const products = useSelector(selectAllProducts); // Reemplaza productsSelector con el selector correcto para tu store

  const sauceProduct = products.find((product) => product.category === "Sauce");
  const cartHasSauce = cart.find((product) => product.category === "Sauce");
  const hasFreeProduct = cart.some((product) => product.isFree);

  const totalAmount = cart.reduce((acc, product) => {
    if (product.category === "Sushi") {
      return acc + product.amount;
    }
    return acc;
  }, 0);

  const handleAddToCart = () => {
    if (!cartHasSauce && sauceProduct) {
      dispatch(addToCart(sauceProduct));
    }
  };
  return (
    <div className="flex flex-col max-h-[400px] overflow-y-auto ">
      {cart &&
        cart.map((product) => {
          return <ProductsSummaryCard product={product} key={product._id} />;
        })}
      {totalAmount >= 2 && !hasFreeProduct && <Carousel cart={cart} />}
      {!cartHasSauce && (
        <div
          className="mx-4 mt-5 w-fit rounded-lg shadow-md flex self-center items-center border border-brown cursor-pointer"
          onClick={handleAddToCart}>
          <img
            src={cheeseSauce}
            alt="Cheese Sauce"
            className="w-10 h-10 object-cover rounded-full"
          />
          <div>
            <p className="text-sm">Try our delicious Cheese Sauce!</p>
          </div>
          <button className="transform transition-transform duration-50 ease-in-out active:scale-90">
            <i className="fa fa-plus-circle ml-1 pr-2"></i>
          </button>
        </div>
      )}
    </div>
  );
};
