import { Alert } from "../../components/elements/Alert";
import { useLocation } from "react-router-dom";

const PaymentSuccessCash = () => {
  const location = useLocation();
  const { cart, address } = location.state || {};
  const calculateTotal = (cart) => {
    if (!cart || cart.length === 0) {
      return 0;
    }
    const total = cart.reduce((accumulator, item) => {
      const price = parseFloat(item.price);
      return accumulator + (isNaN(price) ? 0 : price);
    }, 0);

    return `${total.toFixed(2)}`;
  };

  return (
    <div className="flex min-h-96 w-5/6 self-center  justify-center items-center font-western mb-3">
      <div className="max-w-lg mx-auto p-4 bg-beige-light rounded shadow-lg">
        <Alert variant="success">
          <p className="text-lg font-semibold mb-2">Order Successful!</p>
          <p>
            <span className="capitalize">{address.customerName}</span>, your
            order is on its way to{" "}
            <span className="font-semibold">{address.address}</span>
          </p>
          <p>Estimated delivery time: 45 minutes.</p>
        </Alert>

        <div className="mt-4">
          <h2 className="text-lg font-semibold mb-2">Order Details</h2>
          {cart &&
            cart.map((item) => (
              <div key={item._id} className="flex justify-between mb-1">
                <span>
                  x {item.amount} {item.name}
                </span>
                <span>{item.isFree ? "FREE" : `$${item.price}`} </span>
              </div>
            ))}
          <hr className="my-2" />
          {cart && (
            <div className="flex justify-between">
              <span className="font-semibold">Total:</span>
              <span>${calculateTotal(cart)}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessCash;
