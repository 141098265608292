import imageSite from "../../src/assets/images/image_site.png";

export function Banner() {
  return (
    <div className="container banner w-full px-4 sm:px-6 lg:px-10 mx-auto relative flex flex-col mb-3">
      <div
        className="flex h-28 w-60 justify-center items-center self-center"
        style={{
          backgroundImage: `url(${imageSite})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}></div>

      <div className="banner-description w-full md:w-1/2 mt-4 mb-1">
        <h2 className="text-4xl font-bold font-western">Menu</h2>
        <p className="italic text-sm text-gray-600 w-[340px] md:w-full">
          <i className="fa fa-gift text-red-500 mr-1 text-base"></i>
          Delivery Free & Special Offer: Buy 2 Get 1 FREE
        </p>
      </div>
    </div>
  );
}
