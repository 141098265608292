import { combineReducers } from "redux";
import cartReducer from "./cart/cartSlice";
import orderReducer from "./order/ordersSlice";
import productReducer from "./product/productSlice";
import addressReducer from "./userInfo/addressSlice";

const rootReducer = combineReducers({
  cart: cartReducer,
  order: orderReducer,
  address: addressReducer,
  products: productReducer,
});

export default rootReducer;
