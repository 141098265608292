import sushiLogo from "../../src/assets/images/sushi_french_logo.png";
import cartIcon from "../../src/assets/icons/cart.svg";
import { Link } from "react-router-dom";
import { useState } from "react";

export const Header = ({ cartCount }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarOpen((prevNavbarOpen) => !prevNavbarOpen);
  };

  return (
    <nav id="header" className="sticky top-0 z-50 bg-beige w-full">
      <div className="mobile:justify-between container w-full mx-auto flex items-center mt-0 py-2 justify-between">
        <button
          className="container-menu-burguer w-[30px]"
          onClick={() => toggleNavbar()}>
          <i className={`fa text-2xl  ${navbarOpen ? "" : "fa-bars"}`}></i>
        </button>
        <div className="flex">
          <Link
            to="/"
            className="toggleColor text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
            <img src={sushiLogo} alt="logo" className="w-16 object-cover" />
          </Link>
        </div>

        <div className="flex">
          <Link to="/cart" className="badge-cart mr-4 relative">
            {/* <img className="w-[30px]" src={cartIcon} alt="cart" /> */}
            <i className="fa fa-basket-shopping text-2xl"></i>
            {cartCount > 0 ? (
              <div
                id="badge-cart"
                className="rounded-full text-xs bg-red-400 text-white inline-flex justify-center items-center w-5 h-5 absolute -top-1 -right-1">
                {cartCount}
              </div>
            ) : null}
          </Link>
        </div>
      </div>
      {navbarOpen && (
        <div
          className={`fixed z-40 left-0 top-0 w-full h-full bg-opacity-90 bg-black text-white p-4 transition-all duration-600 transform ease-in-out ${
            navbarOpen ? "translate-y-0" : "-translate-y-full"
          }`}>
          <div className="flex flex-col pt-3 w-11/12 md:w-3/4 mx-auto">
            <button
              className="container-menu-burguer w-[30px]"
              onClick={() => toggleNavbar()}>
              <i
                className={`fa text-3xl text-white ${
                  navbarOpen ? "fa-close" : "fa-bars"
                }`}></i>
            </button>
            <ul
              className="list-none p-0 mt-10 flex flex-col text-center text-xl font-western"
              onClick={() => toggleNavbar()}>
              <Link to="/">
                <li className="py-3 hover:bg-gray-800">Menu</li>
              </Link>

              <Link to="/about">
                <li className="py-3 hover:bg-gray-800">About</li>
              </Link>

              <Link to="/contact">
                <li className="py-3 hover:bg-gray-800">Contact</li>
              </Link>
            </ul>
          </div>
          <div className="container h-full" onClick={toggleNavbar}></div>
        </div>
      )}
    </nav>
  );
};
