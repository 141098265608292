import { useState, useEffect } from "react";
import { ProductPreviewCard } from "./ProductPreviewCard";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../stores/cart/cartSlice";

export function Carousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const dispatch = useDispatch();

  const products = useSelector((state) => state.products.products);

  const sushiProducts = products.filter(
    (product) =>
      product.category === "Sushi" &&
      product.hasStock &&
      product.name !== "Le Marseillais"
  );

  const onAddProduct = (product) => {
    dispatch(addToCart(product));
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === sushiProducts.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? sushiProducts.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="carousel-container flex justify-center container py-3 mb-2 w-full">
      <div className="carousel relative">
        {sushiProducts.map((product, index) => (
          <div
            key={index}
            className={
              index === currentSlide
                ? "slide opacity-100 scale-100"
                : "slide opacity-0 scale-75 absolute top-0 left-0 transition-opacity duration-300"
            }>
            {index === currentSlide && (
              <ProductPreviewCard
                isCarousel={true}
                product={product}
                onAddProduct={onAddProduct}
              />
            )}
          </div>
        ))}
        <button
          className="prev absolute top-1/2 right-32 transform -translate-y-1/2 flex items-center justify-center"
          onClick={prevSlide}>
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg
              className="w-4 h-4 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button
          className="next absolute top-1/2 left-32 transform -translate-y-1/2 flex items-center justify-center"
          onClick={nextSlide}>
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full group-focus:outline-none">
            <svg
              className="w-4 h-4 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
