import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function AdminLogin({ adminAuthenticated, setAdminAuthenticated }) {
  const [password, setPassword] = useState("");
  const correctPassword = `${process.env.REACT_APP_CORRECT_PASSWORD}`;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validación de contraseña (esto es solo un ejemplo básico)
    if (password === correctPassword) {
      setAdminAuthenticated(true);
      navigate("/admin/orders");
    } else {
      setErrorMessage("Incorrect password");
    }
    // Redireccionar si ya está autenticado como administrador
    if (adminAuthenticated) {
      navigate("/admin/orders");
    }
  };

  return (
    <div className="flex justify-center items-center h-[70vh] ">
      <div className="bg-white p-8 rounded shadow-xl w-80">
        <h1 className="text-2xl font-bold text-center mb-4">Admin Login</h1>
        <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border border-gray-300 px-3 py-2 rounded focus:outline-none focus:border-blue-500"
          />
          {errorMessage && (
            <p className="text-red-500 text-sm">{errorMessage}</p>
          )}
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminLogin;
