import { useState, useEffect } from "react";

const useTabSwitch = (tabs, defaultTab) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const [isPaymentTabEnabled, setIsPaymentTabEnabled] = useState(true); // Nuevo estado para habilitar/deshabilitar la pestaña de pago

  useEffect(() => {
    setCurrentTab(defaultTab);
  }, [defaultTab]);

  const handleTabSwitch = (tab) => {
    //activar/desactivar la pestaña de pago
    if (tab === "Payment" && !isPaymentTabEnabled) {
      return; // Evita cambiar a la pestaña de pago si no está habilitada
    }
    setCurrentTab(tab);
  };

  const setPaymentTabEnabled = (isEnabled) => {
    setIsPaymentTabEnabled(isEnabled);
  };

  return [
    currentTab,
    handleTabSwitch,
    isPaymentTabEnabled,
    setPaymentTabEnabled,
  ];
};

export default useTabSwitch;
