import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "../components/Header";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Menu from "../pages/Menu";
import Cart from "../pages/Cart";
import PaymentSuccess from "../pages/PaymentSuccess";
import PaymentSuccessCash from "../pages/PaymentSuccessCash";
import AdminLogin from "../pages/AdminLogin";
import { useSelector } from "react-redux";
import { cartProducts } from "../stores/cart/cartSlice";
import { Footer } from "../components/Footer";
import { useState } from "react";
import { OrderList } from "../components/OrderList";
import { Navigate } from "react-router-dom";
import PrivateRoute from "../navigation/PrivateRoute";
import { Contact } from "../components/Contact";
import { About } from "../components/About";

const Navigation = () => {
  const productsInCart = useSelector(cartProducts);
  const [adminAuthenticated, setAdminAuthenticated] = useState(false);
  return (
    <BrowserRouter>
      <Header cartCount={productsInCart ? productsInCart.length : 0} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-success-cash" element={<PaymentSuccessCash />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />

        <Route
          path="/admin/login"
          element={<AdminLogin setAdminAuthenticated={setAdminAuthenticated} />}
        />
        <Route
          path="/admin/orders"
          element={<PrivateRoute adminAuthenticated={adminAuthenticated} />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};
export default Navigation;
