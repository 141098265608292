import savoyard from "../images/savoyard.jpeg";
import paris from "../images/paris.jpeg";
import bayon from "../images/bayon.jpeg";
import corse from "../images/corse.jpeg";
import marseillais from "../images/marseillais.jpeg";
import cocacola from "../images/cocacola-can.png";
import sprite from "../images/sprite-can.png";
import fanta from "../images/fanta-can.png";
import springwater from "../images/spring-water.png";
import sparklingwater from "../images/sparkling-water.png";
import cheesesauce from "../images/cheese-sauce.png";

const productImages = {
  "Le Parisien": paris,
  "Le Savoyard": savoyard,
  "Le Bayonnais": bayon,
  "Le Corse": corse,
  "Le Marseillais": marseillais,
  "Coca-Cola": cocacola,
  Sprite: sprite,
  "Fanta Orange": fanta,
  "Sparkling Water": sparklingwater,
  "Spring Water": springwater,
  "Cheese Sauce": cheesesauce,
};

export default productImages;
