import { Tabs } from "../../components/Tabs";
import { useSelector } from "react-redux";
import { cartProducts } from "../../stores/cart/cartSlice";
import { ReactComponent as ArrowRightSvg } from "../../assets/icons/arrow-right-svgrepo-com.svg";
import useTabSwitch from "../../hooks/useTabSwitch";
import { AddressForm } from "../../components/AddressForm";
import { ProductsSummary } from "../../components/ProductsSummary";
import { StripeWrapper } from "../../components/PaymentForm";
import Button from "../../components/elements/Button";
import { Link } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { calculateTotal } from "../../stores/cart/cartSlice";
import OpeningHoursModal from "../../components/OpeningHoursModal";
import { isOpen } from "../../utils/openingHoursUtil";

export const Cart = () => {
  const isOpenHour = isOpen();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [hideModal, setHideModal] = useState(false);

  const handleCloseModal = () => {
    setHideModal(true);
  };

  const cart = useSelector(cartProducts);

  const tabs = ["Summary", "Delivery", "Payment"];
  const [
    currentTab,
    handleTabSwitch,
    isPaymentTabEnabled,
    setPaymentTabEnabled,
  ] = useTabSwitch(tabs, "Summary");
  const addressFormRef = useRef();
  const [isFormValid, setIsFormValid] = useState(false);
  const total = useSelector(calculateTotal);

  const onSubmitAddressForm = (data) => {
    if (addressFormRef.current) {
      handleTabSwitch("Payment");
      scrollToTop();
      addressFormRef.current.submitForm();
    } // Ejecuta el submit del formulario AddressForm
  };

  // Luego puedes llamar scrollToTop donde sea necesario
  useEffect(() => {
    scrollToTop(); // Llama a la función cuando se monta el componente
  }, []);

  useEffect(() => {
    // Verifica si la pestaña actual es "Payment" y ejecuta onSubmitAddressForm
    if (currentTab === "Payment") {
      onSubmitAddressForm();
    }
  }, [currentTab]);

  const handleFormValidityChange = (isValid) => {
    setIsFormValid(isValid);
    setPaymentTabEnabled(isValid && isOpenHour);
  };

  if (!cart || cart.length === 0) {
    return (
      <div className="cart-container font-western h-96 text-black flex flex-col items-center justify-center p-4">
        <OpeningHoursModal hide={hideModal} onClose={handleCloseModal} />
        <h1>Your Cart is empty </h1>
        <Link
          to="/"
          className="mt-4 flex items-center text-blue-500 hover:text-blue-700">
          Go Menu
          <ArrowRightSvg className="w-5 h-5" fill="black" />
        </Link>
      </div>
    );
  }

  return (
    <div className="cart-container flex flex-col mb-5 self-center mx-2 h-[500px] md:h-[500px] mt-2 border border-brown p-1 w-11/12 md:w-2/3 rounded-lg shadow-md sm:p-6 lg:p-8">
      <OpeningHoursModal hide={hideModal} onClose={handleCloseModal} />
      <p className="text-center text-sm font-semibold">
        ⚠ Delivery available only within Brisbane CBD and nearby areas.
      </p>
      <Tabs list={tabs} onTabSwitch={handleTabSwitch} activeTab={currentTab} />

      <div
        className={`tabs ${
          currentTab !== "Summary" ? "hidden" : ""
        } flex flex-col justify-between h-[82%] md:h-5/6`}>
        <ProductsSummary />

        <div className="justify-between container flex pt-2 pl-3">
          <div className="align-center py-3">
            <span className="text-sm md:text-lg font-bold">
              Total: ${total}
            </span>
          </div>
          <Button
            disabled={!isOpenHour}
            className="flex items-center"
            variant="white"
            onClick={() => handleTabSwitch("Delivery")}>
            <span className="mr-1">Next</span>
            <ArrowRightSvg className="w-5 h-5" fill="black" />
          </Button>
        </div>
      </div>
      <div
        className={`tabs ${
          currentTab !== "Delivery" ? "hidden" : ""
        } flex flex-col justify-between h-[82%] md:h-5/6`}>
        <AddressForm
          ref={addressFormRef}
          onFormValidityChange={handleFormValidityChange}
        />
        <div className="justify-between container flex pt-2 pl-3">
          <div className="align-center py-3">
            <span className="text-sm md:text-lg font-bold">
              Total: ${total}
            </span>
          </div>
          <Button
            className="flex items-center"
            type="button"
            variant="white"
            onClick={() => onSubmitAddressForm()}
            disabled={!isFormValid || !isOpenHour}>
            <span className="mr-1">Next</span>
            <ArrowRightSvg className="w-5 h-5" fill="black" />
          </Button>
        </div>
      </div>
      <div
        className={`tabs ${
          currentTab !== "Payment" ? "hidden" : ""
        } container flex flex-col pt-5 px-3`}>
        <StripeWrapper />
      </div>
    </div>
  );
};

export default Cart;
