import "../styles/Footer.css";
import { isMobile } from "react-device-detect";

export const Footer = () => {
  const instagramUsername = "atelier.sushis";
  const reviewLink = "https://g.page/r/CTtI90mB97opEBM/review"; // La URL específica de revisión en Google

  const handleClick = () => {
    window.open(reviewLink, "_blank");
  };

  const openInstagram = () => {
    if (isMobile) {
      window.location.href = `instagram://user?username=${instagramUsername}`;
    } else {
      window.open(`https://www.instagram.com/${instagramUsername}`, "_blank");
    }
  };
  return (
    <footer id="footer" className="bg-beige-light footer">
      <div className="container mx-auto p-3 lg:px-0">
        <div className="flex flex-col text-gray-500 mb-6 items-center gap-4">
          <h1 className="text-3xl font-bold tracking-wide text-gray-800 font-western">
            L'Atelier Sushi
          </h1>

          <p className="text-sm text-center text-gray-600 italic mt-2">
            Follow us on Instagram and send us a message to get{" "}
            <span className="text-red-500 font-semibold">1 free drink!</span> on
            your next order.
          </p>

          <div className="icon">
            <i
              className="fab fa-instagram fa-3x text-white relative z-10 mt-1.5"
              onClick={openInstagram}
              style={{ cursor: "pointer" }}></i>
          </div>

          <button className="py-2 px-4 rounded" onClick={handleClick}>
            <p className="text-center">Leave a good review!</p>
            <div className="flex items-center justify-center">
              <i className="fas fa-star text-yellow-300 ml-1"></i>
              <i className="fas fa-star text-yellow-300 ml-1"></i>
              <i className="fas fa-star text-yellow-300 ml-1"></i>
              <i className="fas fa-star text-yellow-300 ml-1"></i>
              <i className="far fa-star-half-alt text-yellow-300 ml-1"></i>
            </div>
          </button>
          <div className="text-sm text-center mt-10">
            <p>
              <i className="far fa-envelope"></i>{" "}
              lateliersushis.contact@gmail.com
            </p>
            <p>
              <i className="fas fa-phone-alt"></i> (+61) 0426230943
            </p>
            <p>
              <i className="fas fa-phone-alt"></i> (+61) 0466624190{" "}
            </p>
            <p>79 Albert St, Brisbane, Australia</p>
          </div>
        </div>
        <h2 className="leading-[0] text-[0]">L'Atelier Sushi</h2>
      </div>
    </footer>
  );
};

export default Footer;
