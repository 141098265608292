import woodenFrame from "../assets/images/about.png";
export const About = () => {
  return (
    <div className="">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-16 text-center">
        <div className="relative">
          <img src={woodenFrame} alt="wood frame" className="w-96 mx-auto" />
          <div className="absolute inset-0 mx-auto flex flex-col items-center justify-center w-80">
            <div className="flex flex-col mt-16 w-5/6 md:w-full lg:flex-row justify-center items-center lg:gap-12">
              <p className="text-gray-700 text-xs leading-relaxed font-western">
                Step into "L'Atelier Sushi" 'neath Brisbane's night aglow, Where
                love for flavors waltzes, a celestial show. Sushis donned in the
                soft embrace of French delight, Free deliveries, love's gift
                wrapped in the night. Each bite, a love sonnet, whispered so
                sweet, A culinary dance where romance and flavors meet. Fresh
                ingredients, stars in our culinary sky, A love story told in
                each roll, oh my, oh my. Fusion of Japan and France, a
                rendezvous divine, In "L'Atelier Sushi," where love intertwines.
                Let us sweep you in, on a romantic spree, A symphony of flavors
                at love's sweet decree.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
