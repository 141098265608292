import { Navigate } from "react-router-dom";
import OrderList from "../components/OrderList";

const PrivateRoute = ({ adminAuthenticated }) => {
  return adminAuthenticated ? (
    <OrderList />
  ) : (
    <Navigate to="/admin/login" replace={true} />
  );
};

export default PrivateRoute;
