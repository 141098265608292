import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiURL from "../../config";

const initialState = {
  products: [],
  error: null,
  status: "idle",
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    updateProductObservations: (state, action) => {
      const { productId, observations } = action.payload;
      const productToUpdate = state.products.find(
        (product) => product._id === productId
      );
      if (productToUpdate) {
        productToUpdate.observations = observations;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.products = [...action.payload.data];
    });
    builder.addCase(fetchProducts.pending, (state, action) => {
      state.status = "pending";
    });
  },
});

export const { updateProductObservations } = productsSlice.actions;

export default productsSlice.reducer;

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    const response = await fetch(`${apiURL}/api/products`);
    const data = await response.json();
    return data;
  }
);
export const selectAllProducts = (state) => state.products.products;
