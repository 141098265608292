export const TabItem = ({ title, index, active, setActive }) => {
  const className = active ? "border-brown" : "border-none text-slate-400";

  return (
    <div className="nav-item px-1 md:px-3">
      <button onClick={() => setActive(title)} className="pt-1 pb-2">
        <span
          className={`hover:text-black text-base md:text-lg transition-colors border-b-2 ${className}`}>
          {title.toUpperCase()}
        </span>
      </button>
    </div>
  );
};
