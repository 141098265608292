import { Banner } from "../../components/Banner";
import { ProductsPreview } from "../../components/ProductsPreview";

export function Home() {
  return (
    <>
      <Banner />
      <ProductsPreview />
    </>
  );
}

export default Home;
