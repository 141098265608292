import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/elements/Button";
import { app } from "../../firebase-config";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const handleAuthError = (error) => {
    setLoading(false);
    let errorMessage = "";
    let errorPassword = "";

    switch (error.code) {
      case "auth/email-already-in-use":
        errorMessage = "Email already in use";
        break;
      case "auth/invalid-email":
        errorMessage = "Invalid email address";
        break;
      case "auth/user-disabled":
        errorMessage = "User account is disabled";
        break;
      case "auth/user-not-found":
        errorMessage = "User not found";
        break;
      case "auth/wrong-password":
        errorPassword = "Incorrect password";
        errorMessage = errorPassword;
        break;
      case "auth/weak-password":
        errorPassword = "Password should be at least 6 characters";
        errorMessage = errorPassword;
        break;
      case "auth/missing-password":
        errorPassword = "Missing password";
        errorMessage = errorPassword;
        break;
      case "auth/missing-email":
        errorMessage = "Missing email";
        break;
      case "auth/operation-not-allowed":
        errorMessage = "Operation not allowed";
        break;
      case "auth/too-many-requests":
        errorMessage = "Too many requests, please try again later";
        break;
      case "auth/credential-already-in-use":
        errorMessage = "This credential is already in use";
        break;
      default:
        errorMessage = "An error occurred while signing in";
        break;
    }

    toast.error(errorMessage);
    setErrorMessage(errorMessage);
    setErrorPassword(errorPassword);
  };

  const handleUserCreation = async (uid, data) => {
    const response = await fetch("http://localhost:8080/api/create-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
        name: data.name,
        _id: uid,
      }),
    });

    if (response.ok) {
      setLoading(false);
      toast.success("Account created successfully!🎉");
      navigate("/");
    } else {
      setLoading(false);
      const data = await response.json();
      setErrorMessage(data.error);
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    const auth = getAuth();

    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((response) => {
        const uid = response.user.uid;
        sessionStorage.setItem("User Id", uid);
        sessionStorage.setItem(
          "Auth token",
          response._tokenResponse.refreshToken
        );
        window.dispatchEvent(new Event("storage"));

        handleUserCreation(uid, data);
      })
      .catch(handleAuthError);
  };
  return (
    <div className="h-screen className flex  items-center justify-center">
      <div className="rounded-lg max-w-md w-full flex flex-col items-center justify-center relative">
        <div className="absolute inset-0 transition duration-300 animate-pink blur  gradient"></div>
        <div className="p-10 rounded-xl z-10 w-full h-full">
          <h5 className="text-3xl">Register</h5>
          <form className="w-full space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="name"
                className="block text-lg font-medium className">
                Name
              </label>
              <input
                {...register("name")}
                id="name"
                type="text"
                className="block appearance-none w-full px-3 py-2 border border-gray-300 roundedn-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-200 focus:border-gray-200"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-lg font-medium className">
                Email
              </label>
              <input
                {...register("email")}
                id="email"
                type="email"
                className="block appearance-none w-full px-3 py-2 border border-gray-300 roundedn-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-200 focus:border-gray-200"
              />
              {errorMessage && !errorPassword && (
                <p className="text-red-500">{errorMessage}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-lg font-medium className">
                Password
              </label>
              <input
                {...register("password", { minLength: 6 })}
                id="password"
                type="password"
                className="block appearance-none w-full px-3 py-2 border border-gray-300 roundedn-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-200 focus:border-gray-200"
              />
              {errorPassword && ( // Mostrar el mensaje de error si hay uno
                <p className="text-red-500">{errorPassword}</p>
              )}
            </div>

            <Button
              size="large"
              disabled={
                loading ||
                !watch("name") ||
                !watch("email") ||
                !watch("password") ||
                !isValid
              }>
              {loading ? "loading" : "Register"}
            </Button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Register;
