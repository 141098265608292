import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Spinner from "../assets/icons/spinner";
import { ProductPreviewCardSmall } from "../components/ProductPreviewCardSmall";
import productImages from "../assets/data/productImages";

export const ProductPreviewCard = ({ product, onAddProduct, isCarousel }) => {
  const mobileStylesToast = {
    width: "70%",
    margin: "40px",
    // Otros estilos para dispositivos móviles
  };

  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageLoaded = () => {
    setLoading(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const addProduct = () => {
    onAddProduct(product);
    toast.success("Product added! 🎉", {
      position: "top-right",
      autoClose: 300,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: false,
    });
  };

  if (isCarousel) {
    // Si es un carrusel, renderiza el componente SmallProductPreviewCard
    return (
      <ProductPreviewCardSmall product={product} onAddProduct={onAddProduct} />
    );
  }

  return (
    <div
      className={`w-full h-auto max-w-sm bg-beige-light border border-brown rounded-lg shadow-western ${
        !product.hasStock ? "opacity-50" : ""
      }`}>
      <div className="flex items-center justify-between">
        <h1
          className={`text-2xl px-4 pt-3 font-western tracking-tight text-brown`}>
          {product.name}
        </h1>
        {product.adjective === "Popular" && (
          <div className="bg-green-600 font-western text-white text-xs px-2 py-1 mt-3 mr-3 rounded-lg">
            {product.adjective}
          </div>
        )}
      </div>
      <div
        className="img-container sm:h-[90px] md:h-[115px]"
        onClick={openModal}>
        {loading && (
          <div className="p-10">
            <Spinner />
          </div>
        )}
        <img
          className={`p-2 pl-4 rounded-full w-[90px] md:w-[130px] cursor-pointer h-auto ${
            loading ? "hidden" : ""
          }`}
          src={productImages[product.name]}
          alt={product.name}
          onLoad={handleImageLoaded}
        />
      </div>
      <div className="px-5 pb-3">
        <p className="text-base text-brown pb-2 italic md:min-h-[85px]">
          {product.description}
        </p>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-2xl font-semibold text-brown">
              {`$${product.price}`}
            </span>

            {product.name === "Le Parisien" && (
              <span className="text-sm text-gray-500 ml-1 line-through">
                $35
              </span>
            )}
          </div>

          {!product.hasStock ? (
            <button
              disabled
              className="font-normal cursor-not-allowed py-2 px-4 rounded-xl border-western border shadow-lg transform transition-transform duration-50 ease-in-out active:scale-90">
              Out of stock
            </button>
          ) : (
            <button
              onClick={addProduct}
              className="font-normal py-2 px-4 rounded-xl border-western border shadow-lg transform transition-transform duration-50 ease-in-out active:scale-90">
              +
            </button>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div
          className="fixed z-20 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center md:justify-center"
          onClick={closeModal}>
          <button
            className="relative bottom-20 md:bottom-48 left-3/4 lg:left-2/4 m-2 mr-4 cursor-pointer z-30"
            onClick={closeModal}>
            <i className="fa fa-close"></i>
          </button>
          <img
            className="w-3/4 h-auto z-10 rounded-lg lg:h-2/3 lg:w-auto"
            src={productImages[product.name]}
            alt={product.name}
          />
        </div>
      )}
      <ToastContainer
        style={window.innerWidth <= 800 ? mobileStylesToast : null}
      />
    </div>
  );
};
