import productImages from "../assets/data/productImages";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  incrementProductAmount,
  decrementProductAmount,
  removeFreeProduct,
  cartProducts,
  updateCart,
} from "../stores/cart/cartSlice";

import { updateProductObservations } from "../stores/product/productSlice";

export const ProductsSummaryCard = ({ product }) => {
  const dispatch = useDispatch();
  const cart = useSelector(cartProducts);
  const [showObservations, setShowObservations] = useState(false);
  const [observations, setObservations] = useState("");

  const totalAmount = cart.reduce((acc, product) => {
    if (product.category === "Sushi") {
      return acc + product.amount;
    }
    return acc;
  }, 0);
  const hasFreeProduct = cart.some((product) => product.isFree);
  const handleDecrement = () => {
    dispatch(decrementProductAmount(product._id));
    if (
      hasFreeProduct &&
      totalAmount - 1 <= 2 &&
      product.category === "Sushi"
    ) {
      dispatch(removeFreeProduct());
    }
  };

  const handleToggleObservations = () => {
    setShowObservations(!showObservations);
  };

  const handleBlurObservations = () => {
    if (observations !== "") {
      dispatch(
        updateProductObservations({
          productId: product._id,
          observations: observations,
        })
      );
      dispatch(updateCart(updatedCart));
      setShowObservations(false); // Ocultar el área de observaciones después de guardar
    }
  };

  const updatedCart = cart.map((cartProduct) => {
    if (cartProduct._id === product._id) {
      return {
        ...cartProduct,
        observations: observations,
      };
    }
    return cartProduct;
  });

  return (
    <div className="flex items-center p-1 border-b border-brown">
      <div className="product-image p-1 border border-brown mr-2 rounded-lg h-fit w-24 md:w-1/4 lg:w-1/6">
        <img src={productImages[product.name]} alt={product.name} />
      </div>
      <div className="product-info w-full self-start">
        <h3 className="text-lg md:text-lg font-western">{product.name}</h3>
        <p className="text-xs md:text-base text-gray-600 italic">
          {product.description}
        </p>
        {showObservations && (
          <div className="w-full mt-2">
            <textarea
              className="w-full text-xs border border-brown rounded-md focus:outline-none"
              placeholder="Add observations..."
              value={observations}
              onChange={(e) => setObservations(e.target.value)}
              onBlur={handleBlurObservations}></textarea>
          </div>
        )}
      </div>
      {!product.isFree ? (
        <div className="text-sm md:text-lg product-price-qt flex flex-col items-center justify-center">
          <button
            className="py-1 px-1 rounded-lg text-xs"
            onClick={handleToggleObservations}>
            Customise
          </button>

          <div className="price">{`${product.price}$`}</div>
          <div className="quantity flex">
            <button
              className="p-1"
              disabled={product.amount <= 0}
              onClick={() => handleDecrement()}>
              -
            </button>
            <span className="p-1">{product.amount}</span>
            <button
              className="p-1"
              onClick={() => dispatch(incrementProductAmount(product._id))}>
              +
            </button>
          </div>
        </div>
      ) : (
        <button
          className="flex items-center bg-opacity-60 text-yellow-800 py-1 px-2 rounded-md"
          onClick={() => dispatch(decrementProductAmount(product._id))}>
          <i className="fa fa-trash"></i>
          <div className="text-xs">FREE</div>
        </button>
      )}
    </div>
  );
};
