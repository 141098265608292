import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/elements/Button";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const handleAuthError = (error) => {
    setLoading(false);
    let errorMessage = "";
    let errorPassword = "";

    switch (error.code) {
      case "auth/invalid-email":
        errorMessage = "Invalid email address";
        break;
      case "auth/user-disabled":
        errorMessage = "User account is disabled";
        break;
      case "auth/user-not-found":
        errorMessage = "User not found";
        break;
      case "auth/wrong-password":
        errorPassword = "Incorrect password";
        errorMessage = errorPassword;
        break;
      case "auth/weak-password":
        errorPassword = "Password should be at least 6 characters";
        errorMessage = errorPassword;
        break;
      case "auth/missing-password":
        errorPassword = "Missing password";
        errorMessage = errorPassword;
        break;
      case "auth/missing-email":
        errorMessage = "Missing email";
        break;
      case "auth/too-many-requests":
        errorMessage = "Too many requests, please try again later";
        break;
      case "auth/invalid-credential":
        errorMessage = "Invalid email or password";
        break;
      default:
        errorMessage = "An error occurred while signing in";
        break;
    }

    toast.error(errorMessage);
    setErrorMessage(errorMessage);
    setErrorPassword(errorPassword);
  };

  const onSubmit = (data) => {
    setLoading(true);
    const authentication = getAuth();

    signInWithEmailAndPassword(authentication, data.email, data.password)
      .then((response) => {
        const uid = response.user.uid;
        sessionStorage.setItem("User Id", uid);
        sessionStorage.setItem(
          "Auth token",
          response._tokenResponse.refreshToken
        );
        window.dispatchEvent(new Event("storage"));

        setLoading(false);
        toast.success("Successful Login!🎉", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        navigate("/");
      })
      .catch(handleAuthError);
  };

  return (
    <div className="h-screen  flex  items-center justify-center">
      <div className="rounded-lg max-w-md w-full flex flex-col items-center justify-center relative">
        <div className="absolute inset-0 transition duration-300 animate-pink blur  gradient "></div>
        <div className="p-10 rounded-xl z-10 w-full h-full className">
          <h5 className="text-3xl">Login</h5>
          <form className="w-full space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="block text-lg font-medium className">
                Email
              </label>
              <input
                {...register("email")}
                id="email"
                type="email"
                className="block appearance-none w-full px-3 py-2 border border-gray-300 roundedn-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-200 focus:border-gray-200"
              />
              {errorMessage && !errorPassword && (
                <p className="text-red-500">{errorMessage}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-lg font-medium className">
                Password
              </label>
              <input
                {...register("password", { minLength: 6 })}
                id="password"
                type="password"
                className="block appearance-none w-full px-3 py-2 border border-gray-300 roundedn-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-200 focus:border-gray-200"
              />
              {errorPassword && <p className="text-red-500">{errorPassword}</p>}
            </div>
            <Button
              size="large"
              disabled={
                loading || !watch("email") || !watch("password") || !isValid
              }>
              {loading ? "loading" : "Login"}
            </Button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Login;
