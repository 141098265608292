import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrders,
  fetchDeliveredOrders,
  selectAllOrders,
} from "../stores/order/ordersSlice";
import Spinner from "../assets/icons/spinner";
import apiURL from "../config";
import moment from "moment";
import { StockPreview } from "./StockPreview";
import audioNotification from "../assets/sounds/mixkit-bell-notification-933.wav";
import { getDatabase, ref, onChildAdded } from "firebase/database";

export const OrderList = () => {
  const dispatch = useDispatch();
  const { orders, status } = useSelector(selectAllOrders);
  const [loading, setLoading] = useState(false);
  const [showDeliveredOrders, setShowDeliveredOrders] = useState(false);
  const [showNewOrderMessage, setShowNewOrderMessage] = useState(false);
  const audioRef = new Audio(audioNotification);
  const componentLoginTime = new Date().getTime();

  useEffect(() => {
    const db = getDatabase();
    const eventsRef = ref(db, "events");

    const eventListener = onChildAdded(eventsRef, (snapshot) => {
      const event = snapshot.val();
      if (event.id > componentLoginTime) {
        dispatch(fetchOrders());
        audioRef.play();
        setShowNewOrderMessage(true);
      }
    });

    return () => {
      eventListener(); // Detiene el listener cuando el componente se desmonta
    };
  }, [dispatch]);

  // scroll
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // refresh orders
  useEffect(() => {
    if (showDeliveredOrders) {
      dispatch(fetchDeliveredOrders());
    } else {
      dispatch(fetchOrders());
    }
  }, [dispatch, showDeliveredOrders]);

  const handleReloadOrders = () => {
    setLoading(true);
    if (showDeliveredOrders) {
      dispatch(fetchDeliveredOrders())
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      dispatch(fetchOrders())
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  };

  const toggleOrders = () => {
    setShowDeliveredOrders(!showDeliveredOrders);
    if (!showDeliveredOrders) {
      dispatch(fetchDeliveredOrders());
    } else {
      dispatch(fetchOrders());
    }
  };
  const markAsDelivered = async (orderId, isDelivered) => {
    let confirmed = null;
    if (!isDelivered) {
      confirmed = window.confirm(
        "Are you sure you want to mark this order as delivered?"
      );
    }
    if (confirmed) {
      try {
        const response = await fetch(`${apiURL}/api/update-order/${orderId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isDelivered: true,
          }),
        });

        if (response.ok) {
          const button = document.getElementById(`markAsDelivered_${orderId}`);
          button.textContent = "Delivered";
          button.disabled = true;
          button.classList.add(
            "opacity-50",
            "cursor-not-allowed",
            "bg-green-500",
            "hover:bg-green-600"
          );
        } else {
          console.error("Failed to update order status");
        }
      } catch (error) {
        console.error("Error updating order status:", error);
      }
    }
  };

  const formatOrderTime = (createdAt) => {
    return moment(createdAt).format("HH:mm (DD/MM/YYYY)");
  };

  return (
    <div className="container mx-auto py-6">
      {showNewOrderMessage && (
        <div
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-10 shadow-md rounded-md flex items-center animate-pulse duration-100"
          onClick={() => setShowNewOrderMessage(false)}>
          <p className="mr-2">New Order!</p>
          <button className="focus:outline-none">
            <i className="fa fa-close"></i>
          </button>
        </div>
      )}
      <div className="flex items-center mb-4">
        <h2
          className={`text-lg font-semibold cursor-pointer ${
            !showDeliveredOrders ? "text-blue-500" : ""
          }`}
          onClick={toggleOrders}>
          Order List
        </h2>
        <span className="mx-2">|</span>
        <h2
          className={`text-lg font-semibold cursor-pointer ${
            showDeliveredOrders ? "text-blue-500" : ""
          }`}
          onClick={toggleOrders}>
          Delivered Orders
        </h2>
        <button
          className="flex items-center ml-4 px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80"
          onClick={handleReloadOrders}>
          <svg
            className="w-4 h-4 mx-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
              fillRule="evenodd"
              d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {status !== "fulfilled" ? (
        <div className="text-center">
          <div role="status">
            <Spinner />
          </div>
        </div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {orders &&
            orders.map((order) => (
              <div
                key={order._id}
                className="bg-gray-100 rounded-md p-4 shadow-md">
                <div className="container flex flex-col justify-between">
                  <h2 className="text-lg font-semibold mb-2">
                    Order #{order._id}
                  </h2>

                  <ul className="text-sm text-gray-600 mb-4 overflow-scroll min-h-[100px]">
                    {order.orderItems.map((item) => (
                      <li key={item._id} className="flex justify-between">
                        <span>
                          {item.amount} x {item.name}
                        </span>
                        {item.observations && (
                          <span className="text-xs italic">
                            ({item.observations})
                          </span>
                        )}
                        <span>{item.isFree ? "FREE" : `$${item.price}`} </span>
                      </li>
                    ))}
                  </ul>

                  <div className="text-xs text-gray-500 mb-4">
                    <p className="mb-1">
                      <span className="font-semibold">Customer Name:</span>{" "}
                      {order.shippingAddress.customerName}
                    </p>
                    <p className="mb-1">
                      <span className="font-semibold">Address:</span>{" "}
                      {order.shippingAddress.address} (
                      {order.shippingAddress.postalCode})
                    </p>
                    <p className="mb-1">
                      <span className="font-semibold">Phone:</span>{" "}
                      {order.shippingAddress.phoneNumber}
                    </p>
                    <p className="mb-1">
                      <span className="font-semibold">
                        Placed: {formatOrderTime(order.createdAt)}
                      </span>{" "}
                    </p>
                  </div>

                  <div className="mt-auto flex justify-between items-center">
                    <p className="text-sm font-semibold">
                      Total: ${order.totalPrice / 100}
                      {order.paymentMethod === "cash" && (
                        <i className="ml-2 fa fa-money-bill-wave"></i>
                      )}
                    </p>

                    <button
                      id={`markAsDelivered_${order._id}`}
                      className={` ${
                        order.isDelivered
                          ? "bg-green-500 cursor-not-allowed"
                          : "bg-blue-500 hover:bg-blue-600"
                      } text-white px-3 py-1 rounded-md text-xs`}
                      onClick={() =>
                        markAsDelivered(order._id, order.isDelivered)
                      }>
                      {order.isDelivered ? "Delivered" : "Mark as delivered"}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          {(!orders || orders.length === 0) && (
            <div className="flex justify-center self-center h-96 text-gray-500 text-center mt-4">
              <p>No orders found.</p>
            </div>
          )}
        </div>
      )}
      <p className="text-3xl text-center font-bold my-10">Disable Stock</p>
      <StockPreview />
    </div>
  );
};

export default OrderList;
