// CashPaymentButton.jsx
import { useState } from "react";
import { clearCart } from "../../stores/cart/cartSlice";
import { clearAddress } from "../../stores/userInfo/addressSlice";

const CashPaymentButton = ({ cart, address, dispatch, navigate, apiURL }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleCashPayment = async () => {
    setErrorMessage(null);
    setLoading(true);

    try {
      const response = await fetch(`${apiURL}/api/process-cash-payment`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          orderItems: cart,
          shippingAddress: address,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const cartDetail = cart;
        const addressDetail = address;
        dispatch(clearAddress());
        dispatch(clearCart());
        navigate("/payment-success-cash", {
          state: {
            cart: cartDetail,
            address: addressDetail,
          },
        });
      } else {
        setErrorMessage(data.message || "Payment failed. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again.");
    }

    setLoading(false);
  };

  return (
    <button
      onClick={handleCashPayment}
      disabled={loading}
      className="ml-2 flex items-center justify-center px-4 py-2 rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50">
      <i className="fa fa-money-bill-wave"> </i> Pay Cash
    </button>
  );
};

export default CashPaymentButton;
