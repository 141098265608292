import clsx from "clsx";

function getClassName({ className }) {
  return clsx(
    "text-md rounded-xl transition duration-300 cursor-pointer focus:outline-none focus:ring-2 focus:ring-opacity-50",
    className
  );
}

const sizes = {
  small: "px-4 py-2",
  medium: "px-6 py-4",
  large: "w-full px-4 py-3",
};

const variants = {
  red: "bg-tomato focus:ring-tomato",
  dark: "bg-black focus:ring-white",
  blue: "bg-[#4285F4] hover:bg-blue-600 focus:ring-blue-500",
  white: "bg-white hover:bg-gray-100 focus:ring-gray-200",
};

const Button = ({
  children,
  className,
  disabled,
  size = "small",
  variant = "white",
  ...rest
}) => {
  return (
    <button
      className={clsx(
        sizes[size],
        variants[variant],
        getClassName({ className }),
        "rounded-xl border border-brown",
        "focus:outline-none",
        "min-w-[80px]",
        "h-12",
        {
          "opacity-50 cursor-not-allowed hover:opacity-50 hover:cursor-not-allowed":
            disabled,
        }
      )}
      disabled={disabled}
      {...rest}>
      {children}
    </button>
  );
};

export default Button;
