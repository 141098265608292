import { useEffect, useState } from "react";
import { isOpen } from "../utils/openingHoursUtil";

const OpeningHoursModal = ({ hide, onClose }) => {
  const isOpenHour = isOpen();
  const [canShowModal, setCanShowModal] = useState(false);

  useEffect(() => {
    setCanShowModal(!hide && !isOpenHour);
  }, [hide, isOpenHour]);
  const modalDisplay = canShowModal ? "block" : "hidden";

  const handleClose = () => {
    onClose();
  };

  return (
    <div
      className={`modal font-western fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-[55] ${modalDisplay}`}>
      <div className="modal-content bg-white p-6 rounded-lg shadow-md w-80">
        <span className="close cursor-pointer text-xl" onClick={handleClose}>
          <i className="fas fa-close"></i>
        </span>
        <h2 className="text-xl text-center font-bold mb-4 text-red-500">
          Sorry we are closed
        </h2>
        <div className="flex flex-col gap-2 items-center">
          <p className="text-sm flex">
            <span className="font-bold w-24">Monday:</span>
            <span>11:00 AM - 11:00 PM</span>
          </p>
          <p className="text-sm flex ">
            <span className="font-bold w-24">Tuesday:</span>
            <span>11:00 AM - 11:00 PM</span>
          </p>
          <p className="text-sm flex ">
            <span className="font-bold w-24">Wednesday:</span>
            <span>11:00 AM - 11:00 PM</span>
          </p>
          <p className="text-sm flex ">
            <span className="font-bold w-24">Thursday:</span>
            <span>11:00 AM - 11:00 PM</span>
          </p>
          <p className="text-sm flex ">
            <span className="font-bold w-24">Friday:</span>
            <span>11:00 AM - 11:00 PM</span>
          </p>
          <p className="text-sm flex ">
            <span className="font-bold w-24">Saturday:</span>
            <span>11:00 AM - 11:00 PM</span>
          </p>
          <p className="text-sm flex">
            <span className="font-bold w-24">Sunday:</span>
            <span>11:00 AM - 11:00 PM</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OpeningHoursModal;
