import { useForm } from "react-hook-form";
import { ReactComponent as ArrowRightSvg } from "../assets/icons/arrow-right-svgrepo-com.svg";
import { useDispatch } from "react-redux";
import { setAddress } from "../stores/userInfo/addressSlice";
import { forwardRef, useImperativeHandle, useEffect } from "react";

export const AddressForm = forwardRef(({ onFormValidityChange }, ref) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange", // Habilita la validación en cada cambio
  });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    // Lógica para enviar los datos, por ejemplo:
    dispatch(setAddress(data));
  };

  const submitForm = () => {
    // Aquí ejecutas el envío del formulario utilizando handleSubmit de react-hook-form
    handleSubmit(onSubmit)();
  };

  // Pasamos la función `submitForm` a través de la ref
  useImperativeHandle(ref, () => ({
    submitForm,
    isValid,
  }));

  useEffect(() => {
    onFormValidityChange && onFormValidityChange(isValid);
  }, [isValid, onFormValidityChange]);

  return (
    <form
      ref={ref}
      className="md:w-2/3 md:mx-auto px-3 pt-1"
      onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-1">
        <label className="block text-sm text-gray-700" htmlFor="customerName">
          Your Name
        </label>
        <input
          {...register("customerName", {
            required: true,
            validate: {
              notEmpty: (value) =>
                value.trim() !== "" || "This field is required",
            },
          })}
          className={`w-full px-3 py-2 text-xs leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline ${
            errors.customerName
              ? "border-red-500 mb-0"
              : "text-gray-700 border-brown mb-2"
          }`}
          id="customerName"
          type="text"
          placeholder="Your name"
        />
        {errors.customerName && (
          <p className="text-red-500 text-[9px]">This field is required</p>
        )}
      </div>
      <div className="mb-1">
        <label className="block text-sm text-gray-700" htmlFor="streetAddress">
          Street Address
        </label>
        <input
          {...register("address", {
            required: true,
            validate: {
              notEmpty: (value) =>
                value.trim() !== "" || "This field is required",
            },
          })}
          className={`w-full px-3 py-2 text-xs leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline ${
            errors.address
              ? "border-red-500 mb-0"
              : "text-gray-700 border-brown mb-2"
          }`}
          id="street address"
          type="text"
          placeholder="Street Address"
        />
        {errors.address && (
          <p className="text-red-500 text-[9px]">This field is required</p>
        )}
      </div>
      <div className="flex justify-between mb-1">
        <div className="mb-1 md:mr-2 md:mb-0 ">
          <label className="block text-sm  text-gray-700" htmlFor="country">
            Country
          </label>
          <select
            {...register("country")}
            id="country"
            name="country"
            autoComplete="country-name"
            className="w-full px-3 py-2 mb-1 text-xs leading-tight text-gray-700 border border-brown rounded shadow focus:outline-none focus:shadow-outline">
            <option>Australia</option>
          </select>
        </div>
        <div className="mb-1 md:mr-2 md:mb-0">
          <label className="block text-sm text-gray-700" htmlFor="postalCode">
            Postal Code
          </label>
          <input
            {...register("postalCode", {
              required: "This field is required",
              pattern: {
                value: /^4[0-1]\d{2}$|^4200$/,
                message: "Enter a valid Brisbane CBD postal code",
              },
            })}
            className={`w-full px-3 py-2 text-xs leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline ${
              errors.postalCode
                ? "border-red-500 mb-0"
                : "text-gray-700 border-brown mb-2"
            }`}
            id="postal code"
            type="text"
            placeholder="4000"
          />
          {errors.postalCode && (
            <p className="text-red-500 text-[9px]">
              {errors.postalCode.message}
            </p>
          )}
        </div>
      </div>
      <div className="mb-1 md:mr-2 md:mb-0">
        <label className="block text-sm text-gray-700" htmlFor="email">
          Phone Number
        </label>
        <input
          {...register("phoneNumber", {
            required: "This field is required",
            pattern: {
              // value: /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
              value: /^(?:\+?61\s?)?0?4(?: ?\d){8}$/,
              message: "Enter a valid Australian phone number",
            },
          })}
          className={`w-full px-3 py-2 text-xs leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline ${
            errors.phoneNumber
              ? "border-red-500 mb-0"
              : "text-gray-700 border-brown mb-2"
          }`}
          id="phoneNumber"
          type="phone"
          placeholder="Phone Number"
        />
        {errors.phoneNumber && (
          <p className="text-red-500 text-[9px]">
            {errors.phoneNumber.message}
          </p>
        )}
      </div>
      <div className="mb-1 md:mr-2 md:mb-0">
        <label className="block text-sm text-gray-700" htmlFor="email">
          Email (optional)
        </label>
        <input
          {...register("email")}
          className="w-full border-brown px-3 py-2 mb-2 text-xs leading-tight text-gray-700 border rounded  shadow appearance-none focus:outline-none focus:shadow-outline"
          id="email"
          type="email"
          placeholder="Email address"
        />
      </div>
    </form>
  );
});
