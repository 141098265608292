import productImages from "../assets/data/productImages";
import { useState } from "react";
import apiURL from "../config";

export const StockCard = ({ product }) => {
  const [hasStock, setHasStock] = useState(product.hasStock);

  const toggleStock = async () => {
    try {
      const response = await fetch(
        `${apiURL}/api/products/${product._id}/toggleStock`,
        {
          method: "PUT",
        }
      );

      if (response.ok) {
        setHasStock(!hasStock);
      } else {
        throw new Error("Error al cambiar el stock");
      }
    } catch (error) {
      console.error("Error cambiando el stock:", error);
    }
  };
  return (
    <div
      className="container bg-beige-light flex flex-col items-center border border-brown rounded-lg shadow-western w-32 transform transition-transform duration-50 ease-in-out hover:scale-105 hover:bg-beige active:scale-90"
      onClick={toggleStock}>
      <div className="flex flex-col items-center justify-between">
        <h1 className="pt-2 px-2 text-sm font-western tracking-tight text-brown">
          {product.name}
        </h1>
      </div>

      <div className="px-2 pb-1 h-16">
        <div className="flex items-center justify-between">
          <div className="img-container w-16">
            <img
              className="p-1 w-full h-full"
              src={productImages[product.name]}
              alt={product.name}
            />
          </div>
        </div>
      </div>

      <div className="py-1 px-4 my-2 text-sm font-western bg-brown border border-transparent rounded-md focus:outline-none hover:bg-brown-dark">
        {hasStock ? (
          <i className="fa fa-check fa-2x"></i>
        ) : (
          <i className="fa fa-ban fa-2x"></i>
        )}
      </div>
    </div>
  );
};
