import { TabItem } from "./TabItem";
import { useSelector } from "react-redux";

export const Tabs = ({ list, activeTab, onTabSwitch }) => {
  let active = activeTab === "" ? list[0] : activeTab;

  return (
    <div className="sticky z-190">
      <div className="mx-2 justify-evenly flex align-center py-1 border-b-gray-400 border-b-1">
        {list.map((item, index) => {
          return (
            <TabItem
              title={item}
              key={index}
              index={index}
              active={active === item}
              setActive={onTabSwitch}
            />
          );
        })}
      </div>
    </div>
  );
};
