export const isOpen = () => {
  const brisbaneTime = new Date().toLocaleString("en-US", {
    timeZone: "Australia/Brisbane",
  });
  const brisbaneDate = new Date(brisbaneTime);
  const dayOfWeek = brisbaneDate.getDay(); // 0 para domingo, 1 para lunes, ..., 6 para sábado
  const brisbaneHour = brisbaneDate.getHours();

  const openingHour = 11; // 11 AM
  const closingHour = 23; // 23 PM

  // Verifica si es domingo y si la hora actual está dentro del horario de apertura y cierre
  return brisbaneHour >= openingHour && brisbaneHour < closingHour;
};
